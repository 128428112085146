<template>
  <div class="d-flex flex-column h-100 layout">
    <app-header />
    <main class="flex-fill">
      <div id="content" class="content">
        <router-view />
      </div>
      <portal-target
        name="content"
        class="h-100 position-absolute w-100 portal-content"
        style="overflow: auto; z-index: 20"
        slim
      />
      <share-modal :venue="shareVenue" />
      <bookmark-modal :venue="bookmarkVenue" />
      <deal-modal :venue="dealVenue" />
      <no-deal-modal :show="noDeal" />
      <call-modal :venue="callVenue" />
      <book-modal :venue="bookVenue.venue" />
      <discover-modal :venue="discoverVenue" />
    </main>
    <app-footer />
  </div>
</template>

<script>
import { Header, Footer } from "@/components"
import {
  BookModal,
  ShareModal,
  BookmarkModal,
  CallModal,
  DiscoverModal,
  DealModal,
  NoDealModal
} from "@/components/modals"
import { mapState } from "vuex"
export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    ShareModal,
    BookModal,
    BookmarkModal,
    CallModal,
    DealModal,
    DiscoverModal,
    NoDealModal
  },
  computed: {
    ...mapState({
      shareVenue: state => state.shareVenue,
      bookmarkVenue: state => state.bookmarkVenue,
      dealVenue: state => state.dealVenue,
      callVenue: state => state.callVenue,
      bookVenue: state => state.bookVenue,
      discoverVenue: state => state.discoverVenue,
      noDeal: state => state.noDeal
    })
  }
}
</script>
